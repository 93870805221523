<template>
	<v-container class="pa-2 mb-2 puiFilterGroup-custom" @mouseover.stop="showGroupBtns = true" @mouseout="showGroupBtns = false">
		<!-- Selects reglas -->
		<div v-if="filterGroupModel.rules && filterGroupModel.rules.length > 0">
			<v-row no-gutters v-for="(rule, index) in filterGroupModel.rules" :key="index" class="mb-2">
				<v-col cols="12" class="pt-2 pa-0 pl-0 pr-2 d-flex align-center">
					<rules-form-filter-rule
						:ref="`rule${index}`"
						:ruleIndexProp="index"
						:groupName="groupName"
						:columnsProp="columnsProp"
						:filterColumnValues="filterColumnValues"
						:filterRuleProp="rule"
						:disabled="disabled"
						@change="onFilterRuleChanges(index)"
					></rules-form-filter-rule>
					<v-btn
						icon
						v-if="!disabled"
						@click="removeRule(index)"
						:disabled="filterGroupModel.rules.length == 1"
						class="d-flex justify-center align-center ml-1"
					>
						<v-icon class="delete-btn" small :ripple="false">fa fa-trash-alt</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div>

		<!-- Filter group -->
		<v-col cols="12" v-if="filterGroupModel.groups.length > 0" class="pa-0 pt-4">
			<rules-form-filter-group
				v-for="(group, index) in filterGroupModel.groups"
				:ref="`group${index}`"
				:groupIndexProp="index"
				:filterGroupProp="group"
				:columnsProp="columnsProp"
				:filterColumnValues="filterColumnValues"
				:showSubgroupBtn="showSubgroupBtn"
				:disabled="disabled"
				:key="index"
				@change="onFilterGroupChanges(index)"
			></rules-form-filter-group>
		</v-col>

		<!-- Buttons acciones -->
		<v-col cols="12" class="elevation-0" :class="isRootGroup && filterGroupModel.groups.length > 0 ? 'pa-0 pt-0' : 'pa-0 pt-3'">
			<v-btn
				v-if="disabled === false"
				class="btnGroup btnAddRule elevation-0"
				@click="addRule"
				:disabled="filterGroupModel.rules.length >= maxFilters"
			>
				<v-icon small>far fa-plus</v-icon>
				<span>{{ $t('addRule') }}</span>
			</v-btn>
			<v-btn v-if="showSubgroupBtn && disabled === false" class="btnGroup btnAddGroup elevation-0 ml-1" @click="addSubGroup">
				<v-icon small>fa fa-layer-group</v-icon>
				<span>{{ $t('addSubGroup') }}</span>
			</v-btn>
			<v-btn v-if="!isRootGroup && disabled === false" class="btnGroup btnRemGroup elevation-0 ml-1" @click="removeGroup">
				<v-icon small>fa fa-trash-alt</v-icon>
				<span>{{ $t('removeGroup') }}</span>
			</v-btn>
		</v-col>
	</v-container>
</template>

<script>
import RulesFormFilterRule from './RulesFormFilterRule';

export default {
	name: 'RulesFormFilterGroup',
	components: {
		RulesFormFilterRule
	},
	props: {
		isRootGroup: {
			type: Boolean,
			default: false
		},
		groupIndexProp: {
			type: Number,
			default: 0
		},
		filterGroupProp: {
			required: true
		},
		columnsProp: {
			type: Array,
			required: true
		},
		filterColumnValues: {
			type: Object,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		showSubgroupBtn: {
			type: Boolean
		},
		maxFilters: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			filterGroupModel: this.getDefaultFilterGroupModel(),
			showDeleteRule: null,
			showGroupBtns: null,
			groupOperators: [
				{
					text: 'and',
					value: 'and'
				},
				{
					text: 'or',
					value: 'or'
				}
			]
		};
	},
	computed: {
		groupName() {
			return this.isRootGroup ? this.groupIndexProp.toString() : this.$parent.groupName + '-' + this.groupIndexProp;
		},
		parentGroupName() {
			return this.isRootGroup ? null : this.$parent.groupName;
		}
	},
	watch: {
		filterGroupProp() {
			if (this.filterGroupProp) {
				this.filterGroupModel = this.filterGroupProp;
				this.filterGroupModel.groupOp ? '' : (this.filterGroupModel.groupOp = 'and');
				this.filterGroupModel.groups ? '' : (this.filterGroupModel.groups = []);
				this.filterGroupModel.rules ? '' : (this.filterGroupModel.rules = []);
			}
		},
		filterGroupModel: {
			deep: true,
			handler() {
				console.info('filterGroupModel', this.filterGroupModel);
				this.$emit('change', this.filterGroupModel);
			}
		},
		'filterGroupModel.rules': {
			deep: true,
			handler(newValue, oldValue) {
				console.info('filterGroupModel rules', newValue, oldValue);
				this.$emit('change', this.filterGroupModel);
			}
		}
	},
	created() {
		this.translateGroupOperators();
		if (this.filterGroupProp) {
			this.filterGroupModel = this.filterGroupProp;
			this.filterGroupModel.groupOp ? '' : (this.filterGroupModel.groupOp = 'and');
			this.filterGroupModel.groups ? '' : (this.filterGroupModel.groups = []);
			this.filterGroupModel.rules ? '' : (this.filterGroupModel.rules = []);
		}
	},
	mounted() {
		this.$puiEvents.$on('reset-filtering', this.reset);
		this.$puiEvents.$on(`groupRemoved-${this.groupName}`, this.onGroupRemoved);
		this.$puiEvents.$on(`subGroupAdded-${this.groupName}`, this.onSubGroupAdded);

		if (this.filterGroupModel.rules.length === 0) {
			this.addRule();
		}
	},
	destroyed() {
		this.$puiEvents.$off('reset-filtering');
		this.$puiEvents.$off(`groupRemoved-${this.groupName}`);
		this.$puiEvents.$off(`subGroupAdded-${this.groupName}`);
	},
	methods: {
		getUniqueIndex() {
			let numberSTR = Math.random().toString().replace('.', Math.random().toString().replace('.', '').substring(0, 10)).substring(0, 20);
			let numberInt = parseInt(numberSTR).toFixed();
			let integer = parseInt(numberInt);
			return integer;
		},
		translateGroupOperators() {
			for (let i = 0, length = this.groupOperators.length; i < length; i++) {
				this.groupOperators[i].text = this.$t(this.groupOperators[i].text);
			}
		},
		reset() {
			if (this.isRootGroup) {
				this.filterGroupModel = this.getDefaultFilterGroupModel();
				this.addRule();
			}
		},
		onGroupRemoved(index) {
			//alert('soy el grupo ' + this.groupName + ` recibo el evento en el grupo ${this.groupName} con el indice ${index}`);
			this.filterGroupModel.groups.splice(index, 1);
		},
		onFilterGroupChanges(index) {
			this.filterGroupModel.groups[index] = this.$refs[`group${index}`][0].filterGroupModel;
		},
		onFilterRuleChanges(index) {
			this.filterGroupModel.rules[index] = this.$refs[`rule${index}`][0].filterRuleModel;
		},
		addSubGroup() {
			this.filterGroupModel.groups.push(this.getDefaultFilterGroupModel());
			this.filterGroupModel.groups.at(-1).rules.push(RulesFormFilterRule.methods.getDefaultFilterRuleModel());
		},
		removeGroup() {
			// alert('soy el grupo ' + this.groupName + ` mando el evento al grupo ${this.parentGroupName} con el indice ${this.groupIndexProp}`);
			if (!this.isRootGroup) {
				this.$puiEvents.$emit(`groupRemoved-${this.parentGroupName}`, this.groupIndexProp);
			} else {
				this.groupFilterModel.groups = [];
			}
		},
		addRule() {
			if (this.filterGroupModel.rules.length <= this.maxFilters - 1) {
				this.filterGroupModel.rules.push(RulesFormFilterRule.methods.getDefaultFilterRuleModel());
			}
		},
		removeRule(index) {
			this.filterGroupModel.rules.splice(index, 1);
		},
		getDefaultFilterGroupModel() {
			return {
				rules: [],
				groups: [],
				groupOp: 'and'
			};
		}
	}
};
</script>

<style lang="postcss" scoped>
.btnGroup {
	height: 28px !important;
	color: var(--N-500);
	& span {
		padding-left: 6px;
	}
}
.delete-btn {
	min-height: 100% !important;
}
.puiFilterGroup-custom {
	background-color: var(--N-0);
	border-color: var(--N-100);
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;

	&__groupOperator {
		height: 50px;
	}
	&__bottombar {
		background-color: transparent !important;
	}
	& .puiFilterGroup-custom {
		background-color: var(--N-10);
		& .v-btn {
			background-color: var(--N-10);
		}
		& .puiFilterGroup-custom {
			background-color: var(--N-0);
			& .v-btn {
				background-color: var(--N-0);
			}
			& .puiFilterGroup-custom {
				background-color: var(--N-10);
				& .v-btn {
					background-color: var(--N-10);
				}
				& .puiFilterGroup-custom {
					background-color: var(--N-0);
					& .v-btn {
						background-color: var(--N-0);
					}
					& .puiFilterGroup-custom {
						background-color: var(--N-10);
						& .v-btn {
							background-color: var(--N-10);
						}
						& .puiFilterGroup-custom {
							background-color: var(--N-0);
							& .v-btn {
								background-color: var(--N-0);
							}
						}
					}
				}
			}
		}
	}
}
</style>
