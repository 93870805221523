<template>
	<v-layout wrap class="RulesFormFilterRule pt-2">
		<!-- Columna -->
		<v-flex xs8 sm4>
			<v-autocomplete
				ref="columnSelector"
				class="pr-1"
				:placeholder="$t('form.common.chooseParameter')"
				append-icon="fa fa-angle-down"
				solo
				flat
				:items="columnsProp"
				item-text="title"
				item-value="name"
				return-object
				v-model="column"
				:disabled="disabled"
				@input="
					clearValues();
					setDefaultOp();
				"
				clearable
				required
			></v-autocomplete>
		</v-flex>

		<!-- Operador -->
		<v-flex xs4 sm3 class="operator">
			<v-select
				class="pr-1"
				append-icon="fa fa-angle-down"
				solo
				flat
				:items="operators"
				:placeholder="$t('form.common.chooseOperator')"
				v-model="filterRuleModel.op"
				:disabled="disabled"
				@input="clearValues()"
				required
			></v-select>
		</v-flex>
		<!-- Data -->
		<!-- Caso de que operador es between o not between, dos inputs, depende del tipo: fecha o numero-->
		<v-flex
			xs12
			sm5
			v-if="filterRuleModel.op === 'bt' || filterRuleModel.op === 'nbt' || filterRuleModel.op === 'avg_bt' || filterRuleModel.op === 'avg_nbt'"
		>
			<v-layout>
				<v-flex xs6>
					<v-text-field
						v-if="column.type !== 'date'"
						class="pr-1 inputFilterText"
						type="number"
						solo
						flat
						outlined
						required
						v-model="value1"
						:disabled="disabled"
					></v-text-field>
					<!-- <pui-number-field
						v-if="column.type !== 'date'"
						v-model="value1"
						:label="this.$t('form.common.minValue')"
						toplabel
						class="pr-1 inputFilterText"
						style="margin-top: -21px"
						required
					></pui-number-field> -->
					<v-text-field
						v-else
						class="inputFilterText"
						type="date"
						solo
						flat
						outlined
						required
						v-model="value1"
						:disabled="disabled"
					></v-text-field>
				</v-flex>
				<v-flex xs6>
					<v-text-field
						v-if="column.type !== 'date'"
						class="inputFilterText"
						type="number"
						solo
						flat
						outlined
						required
						v-model="value2"
						:disabled="disabled"
					></v-text-field>
					<!-- <pui-number-field
						v-if="column.type !== 'date'"
						class="inputFilterText"
						style="margin-top: -21px"
						v-model="value2"
						:label="this.$t('form.common.maxValue')"
						toplabel
						required
						:disabled="disabled"
					></pui-number-field> -->
					<v-text-field
						v-else
						class="inputFilterText"
						type="date"
						solo
						flat
						outlined
						required
						v-model="value2"
						:disabled="disabled"
					></v-text-field>
				</v-flex>
			</v-layout>
		</v-flex>
		<!-- Caso que sean fechas comparadas con HOY +- X dias, un solo input de tipo numero -->
		<v-flex xs12 sm5 v-else-if="isTodayOperator(filterRuleModel.op)">
			<v-layout>
				<v-flex xs3>
					<v-select
						class="pr-1"
						append-icon="fa fa-angle-down"
						solo
						flat
						:items="todayOperators"
						placeholder="+"
						v-model="value1"
						:disabled="disabled"
						required
					></v-select>
				</v-flex>
				<v-flex xs4>
					<v-text-field
						class="pr-1 inputFilter"
						type="number"
						solo
						flat
						outlined
						required
						v-model="value2"
						@keydown="onTodayOperatorValue2KeyDown"
					>
						<!-- <pui-number-field
						v-model="value2"
						:label="this.$t('form.common.value')"
						toplabel
						required
						@keydown="onTodayOperatorValue2KeyDown"
						class="pr-1 inputFilter"
						style="margin-top: -21px"
					>
						<template slot="append">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-icon
										@click.native="toggleTodaySelector()"
										:color="todaySelector == 'TODAY' ? null : 'secondary'"
										class="pointer"
										small
										v-on="on"
										>fa fa-chess-clock</v-icon
									>
								</template>
								<span>{{ todaySelectorTooltip() }}</span>
							</v-tooltip>
						</template></pui-number-field
					> -->
						<template slot="append">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-icon
										@click.native="toggleTodaySelector()"
										:color="todaySelector == 'TODAY' ? null : 'secondary'"
										class="pointer"
										small
										v-on="on"
										>fa fa-chess-clock</v-icon
									>
								</template>
								<span>{{ todaySelectorTooltip() }}</span>
							</v-tooltip>
						</template>
					</v-text-field>
				</v-flex>
				<v-flex xs5>
					<v-select
						append-icon="fa fa-angle-down"
						solo
						flat
						required
						:items="todayRange"
						v-model="value3"
						:placeholder="$t('form.puialertconfiguration.timeunit_days')"
						:disabled="disabled"
					></v-select>
				</v-flex>
			</v-layout>
		</v-flex>
		<!-- Caso que sea el operador 'in' o 'not in', solo input de tipo texto -->
		<v-flex xs12 sm5 v-else-if="filterRuleModel.op === 'in' || filterRuleModel.op === 'ni'">
			<v-text-field
				v-if="!columnHasFilterColumnValues"
				class="inputFilter"
				type="text"
				solo
				flat
				outlined
				required
				:disabled="disabled"
				v-model="value1"
			></v-text-field>
			<v-autocomplete
				v-else
				append-icon="fa fa-angle-down"
				solo
				flat
				required
				:items="getFilterColumnItemsFromAllowedValues()"
				v-model="vSelectMultipleValue"
				multiple
				:disabled="disabled"
			>
			</v-autocomplete>
		</v-flex>
		<!-- Caso que no sea between ni fechas comparadas con HOY +- X dias, entonces un solo imput, depende del tipo: fecha, numero o texto -->
		<v-flex xs12 sm5 v-else>
			<v-autocomplete
				v-if="(columnHasFilterColumnValues && filterRuleModel.op === 'eq') || (columnHasFilterColumnValues && filterRuleModel.op === 'ne')"
				append-icon="fa fa-angle-down"
				solo
				flat
				required
				:items="getFilterColumnItemsFromAllowedValues()"
				v-model="value1"
				:disabled="disabled"
			>
			</v-autocomplete>
			<template v-else>
				<v-text-field
					v-if="column.type === 'date' || column.type === 'datetime'"
					v-show="!(filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn')"
					class="inputFilter"
					type="date"
					solo
					flat
					outlined
					required
					:disabled="filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn' || disabled"
					v-model="value1"
				></v-text-field>
				<v-text-field
					v-else-if="column.type === 'numeric' || column.type === 'decimal'"
					v-show="!(filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn')"
					class="inputFilter"
					type="number"
					solo
					flat
					outlined
					required
					:disabled="filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn' || disabled"
					v-model="value1"
				></v-text-field>
				<!-- <pui-number-field
					v-else-if="column.type === 'numeric' || column.type === 'decimal'"
					v-show="!(filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn')"
					v-model="value1"
					required
					:disabled="filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn' || disabled"
					class="inputFilter"
					style="margin-top: -21px"
					:label="this.$t('form.common.value')"
					toplabel
				></pui-number-field> -->
				<v-text-field
					v-else
					v-show="!(filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn')"
					class="inputFilter"
					type="text"
					solo
					flat
					outlined
					required
					:disabled="filterRuleModel.op === 'nu' || filterRuleModel.op === 'nn' || disabled"
					v-model="value1"
				>
					<template slot="append">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon
									@click.native="filterRuleModel.caseSensitiveAndAccents = !filterRuleModel.caseSensitiveAndAccents"
									:color="filterRuleModel.caseSensitiveAndAccents ? 'secondary' : null"
									class="pointer"
									small
									v-on="on"
									>fa fa-font-case</v-icon
								>
							</template>
							<span>{{ $t('puidatatables.caseSensitive') }}</span>
						</v-tooltip>
					</template>
				</v-text-field>
			</template>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'RulesFormFilterRule',
	props: {
		groupName: {
			type: String
		},
		ruleIndexProp: {
			type: Number
		},
		filterRuleProp: {
			type: Object
		},
		columnsProp: {
			type: Array,
			required: true
		},
		filterColumnValues: {
			type: Object,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			filterRuleModel: this.getDefaultFilterRuleModel(),
			column: {
				type: '',
				name: '',
				title: ''
			},
			value1: null,
			value2: null,
			value3: null,
			vSelectMultipleValue: null,
			todaySelector: 'TODAY', // [ TODAY | NOW ]
			todayOperators: [
				{ text: '+', value: '+' },
				{ text: '-', value: '-' }
			],
			todayRange: [
				{ text: this.$t('form.puialertconfiguration.timeunit_hours'), value: 'HOURS' },
				{ text: this.$t('form.puialertconfiguration.timeunit_days'), value: 'DAYS' },
				{ text: this.$t('form.puialertconfiguration.timeunit_weeks'), value: 'WEEKS' },
				{ text: this.$t('form.puialertconfiguration.timeunit_months'), value: 'MONTHS' },
				{ text: this.$t('form.puialertconfiguration.timeunit_years'), value: 'YEARS' }
			],
			defaultEQTModel: {
				type: 'TODAY',
				sign: '+',
				unitValue: 0,
				unitType: 'DAYS'
			},
			ruleEQTModel: this.defaultEQTModel,
			filterOperators: {
				common: [
					{
						text: 'equal',
						value: 'eq'
					},
					{
						text: 'notEqual',
						value: 'ne'
					},
					{
						text: 'isNotNull',
						value: 'nn'
					},
					{
						text: 'isNull',
						value: 'nu'
					}
					/* {
						text: 'in',
						value: 'in'
					},
					{
						text: 'notIn',
						value: 'ni'
					} */
				],
				numeric: [
					{
						text: 'lessThan',
						value: 'lt'
					},
					{
						text: 'greaterEqualThan',
						value: 'gte'
					},
					{
						text: 'lessEqualThan',
						value: 'lte'
					},
					{
						text: 'greaterThan',
						value: 'gt'
					},
					{
						text: 'between',
						value: 'bt'
					},
					{
						text: 'notBetween',
						value: 'nbt'
					},
					/* AVERAGE OPERATORS */
					// TODO: Save to store to access later from 'NotificationsActive'
					{
						text: 'form.filterOperators.averageLessThan',
						value: 'avg_lt'
					},
					{
						text: 'form.filterOperators.averageGreaterEqualThan',
						value: 'avg_gte'
					},
					{
						text: 'form.filterOperators.averageLessEqualThan',
						value: 'avg_lte'
					},
					{
						text: 'form.filterOperators.averageGreaterThan',
						value: 'avg_gt'
					},
					{
						text: 'form.filterOperators.averageBetween',
						value: 'avg_bt'
					},
					{
						text: 'form.filterOperators.averageNotBetween',
						value: 'avg_nbt'
					}
				],
				text: [
					{
						text: 'contains',
						value: 'cn'
					},
					{
						text: 'notContains',
						value: 'nc'
					},
					{
						text: 'endsWith',
						value: 'ew'
					},
					{
						text: 'notEndsWith',
						value: 'en'
					},
					{
						text: 'beginsWith',
						value: 'bw'
					},
					{
						text: 'notBeginsWith',
						value: 'bn'
					}
				],
				date: [], //son igual que los numericos, solo que tendran un datetime picker en el input,
				datetime: [
					{
						text: 'equalToday',
						value: 'eqt'
					},
					{
						text: 'notEqualToday',
						value: 'net'
					},
					{
						text: 'lessThanToday',
						value: 'ltt'
					},
					{
						text: 'greaterThanToday',
						value: 'gtt'
					},
					{
						text: 'lessEqualThanToday',
						value: 'let'
					},
					{
						text: 'greaterEqualThanToday',
						value: 'get'
					}
				], //idem
				decimal: [], //idem
				empty: []
			},
			emptyDisabled: false
		};
	},
	computed: {
		isRule0() {
			return this.ruleIndexProp === 0;
		},
		operators() {
			let filterOperatorsSorted = this.filterOperators[this.column.type];
			if (filterOperatorsSorted && Array.isArray(filterOperatorsSorted)) {
				if (this.column.type === 'date' || this.column.type === 'datetime') {
					filterOperatorsSorted = filterOperatorsSorted.filter(
						(obj) => obj.value !== 'bt' && obj.value !== 'nbt' && obj.value !== 'in' && obj.value !== 'ni'
					);
				}
				filterOperatorsSorted.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

				// A la lista de operadores ordenados, si el modelo tiene filterColumnValues (lista de valores permitidos prefijada)
				// le eliminamos los siguientes operadores que sabemos que no son necesarios
				if (this.columnHasFilterColumnValues) {
					let filterOperatorsSortedAndTrimmedForSelectedValues = [];
					filterOperatorsSorted.forEach((op) => {
						if (op.value != 'cn' && op.value != 'nc' && op.value != 'ew' && op.value != 'en' && op.value != 'bw' && op.value != 'bn') {
							filterOperatorsSortedAndTrimmedForSelectedValues.push(op);
						}
					});
					return filterOperatorsSortedAndTrimmedForSelectedValues;
				}

				return filterOperatorsSorted;
			}
			return [];
		},
		columnHasFilterColumnValues() {
			for (const colName in this.filterColumnValues) {
				return colName == this.column.name;
			}
			return false;
		}
	},
	watch: {
		filterRuleProp() {
			this.filterRuleModel = this.filterRuleProp;
			this.setValues();
		},
		'column.name'(value) {
			this.filterRuleModel.field = value;
			this.getFilterOperatorsFromFilterRuleModelField();
		},

		value1: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				//para el caso de between o not between
				if (
					this.filterRuleModel.op === 'bt' ||
					this.filterRuleModel.op === 'nbt' ||
					this.filterRuleModel.op === 'avg_bt' ||
					this.filterRuleModel.op === 'avg_nbt'
				) {
					this.filterRuleModel.data = [];
					this.filterRuleModel.data = [newValue, this.value2 ?? 0];
				} else if (this.isTodayOperator(this.filterRuleModel.op)) {
					this.ruleEQTModel.sign = newValue;
					this.filterRuleModel.data = this.ruleEQTModel;
				} else {
					this.filterRuleModel.data = newValue;
				}
			},
			deep: true
		},
		value2: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.info('value2 from', oldValue, 'to ', newValue);
				let tempFilter = this.filterRuleModel;
				if (
					this.filterRuleModel.op === 'bt' ||
					this.filterRuleModel.op === 'nbt' ||
					this.filterRuleModel.op === 'avg_bt' ||
					this.filterRuleModel.op === 'avg_nbt'
				) {
					this.filterRuleModel.data = [];
					this.filterRuleModel.data = [this.value1 ?? 0, newValue];
				} else if (this.isTodayOperator(this.filterRuleModel.op)) {
					this.ruleEQTModel.unitValue = newValue;
					this.filterRuleModel.data = this.ruleEQTModel;
				} else {
					this.filterRuleModel.data = [];
					this.filterRuleModel.data = [this.value1 ?? 0, newValue];
				}
				this.filterRuleModel = tempFilter;
			},
			deep: true
		},
		value3(value) {
			this.ruleEQTModel.unitType = value;
			this.filterRuleModel.data = this.ruleEQTModel;
		},
		vSelectMultipleValue(value) {
			if (value.length > 0) {
				this.filterRuleModel.data = value[0];
				if (value.length > 1) {
					for (let i = 1, length = value.length; i < length; i++) {
						this.filterRuleModel.data += ',';
						this.filterRuleModel.data += value[i];
					}
				}
			}
		},
		todaySelector(value) {
			this.ruleEQTModel.type = value;
			this.filterRuleModel.data = this.ruleEQTModel;
		},
		filterRuleModel: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.info('filterRuleModel from', oldValue, 'to ', newValue);
				this.$emit('change', this.filterRuleModel);
				if (this.filterRuleModel.field == null) {
					this.$refs.columnSelector.lazyValue = null;
				}
			},
			deep: true
		},
		'filterRuleModel.data': {
			handler(newValue, oldValue) {
				console.info('filterRuleModel data from', oldValue, 'to ', newValue);
			},
			deep: true
		}
	},
	created() {
		this.translateFilterOperators();
		this.addCommonAndDecimalAndDateFilterOperators();
		this.filterRuleModel = this.filterRuleProp;
		this.setValues();
	},
	methods: {
		setValues() {
			if (this.filterRuleModel.field !== undefined && this.filterRuleModel.op !== undefined && this.filterRuleModel.data !== undefined) {
				this.getFilterOperatorsFromFilterRuleModelField();
				delete this.filterRuleModel.dataIsColumn; // innecesario - viene del back
				if (this.filterRuleModel.op === 'in' || this.filterRuleModel.op === 'ni') {
					if (!this.columnHasFilterColumnValues) {
						this.value1 =
							Array.isArray(this.filterRuleModel.data) === true ? this.filterRuleModel.data.join(',') : this.filterRuleModel.data;
					} else {
						this.vSelectMultipleValue = this.filterRuleModel.data;
					}
				} else if (Array.isArray(this.filterRuleModel.data)) {
					this.value1 = this.filterRuleModel.data[0];
					this.value2 = this.filterRuleModel.data[1];
				} else if (this.isTodayOperator(this.filterRuleModel.op)) {
					this.ruleEQTModel = this.filterRuleModel.data;
					this.todaySelector = this.ruleEQTModel.type;
					this.value1 = this.ruleEQTModel.sign;
					this.value2 = this.ruleEQTModel.unitValue;
					this.value3 = this.ruleEQTModel.unitType;
				} else {
					this.value1 = this.filterRuleModel.data;
				}
			}
		},
		clearValues() {
			this.filterRuleModel.data = null;
			this.ruleEQTModel = this.defaultEQTModel;
			this.todaySelector = this.ruleEQTModel.type;
			this.value1 = null;
			this.value2 = null;
			this.value3 = null;
			this.vSelectMultipleValue = [];
		},
		setDefaultOp() {
			//this.filterRuleModel.op = 'eq';
			this.filterRuleModel.op = 'gt';
		},
		getFilterOperatorsFromFilterRuleModelField() {
			this.columnsProp.forEach((column) => {
				if (column.name === this.filterRuleModel.field) {
					this.column.name = column.name;
					this.column.type = column.type;
					this.column.title = column.title;
					this.filterOperators[column.type].forEach((operatorsForType) => {
						if (operatorsForType.value === this.filterRuleModel.op) {
							this.filterRuleModel.op = operatorsForType.value;
							// eslint-disable-next-line no-useless-return
							return;
						}
					});
					// eslint-disable-next-line no-useless-return
					return;
				}
			});
		},
		isTodayOperator(operator) {
			return operator === 'eqt' || operator === 'net' || operator === 'ltt' || operator === 'gtt' || operator === 'let' || operator === 'get';
		},
		translateFilterOperators() {
			for (const colType in this.filterOperators) {
				for (let i = 0, length = this.filterOperators[colType].length; i < length; i++) {
					this.filterOperators[colType][i].text = this.$t(this.filterOperators[colType][i].text);
				}
			}
		},
		addCommonAndDecimalAndDateFilterOperators() {
			const commonKey = 'common';
			const emptyKey = 'empty';
			for (const colType in this.filterOperators) {
				if (colType === commonKey) {
					continue;
				} else if (colType === 'decimal') {
					this.filterOperators[colType] = this.filterOperators.numeric;
				} else if (colType === 'date' || colType === 'datetime') {
					this.filterOperators[colType] = this.filterOperators[colType].concat(this.filterOperators.numeric);
				}
				if (colType !== emptyKey) {
					this.emptyDisabled = true;
					this.filterOperators[colType] = this.filterOperators[colType].concat(this.filterOperators[commonKey]);
				}
			}
		},
		getFilterColumnItemsFromAllowedValues() {
			let items = [];

			for (const colName in this.filterColumnValues) {
				if (colName == this.column.name) {
					let entries = Object.entries(this.filterColumnValues[colName]);
					for (const index in entries) {
						items.push({
							text: this.$store.getters.getTranslate(entries[index][0]),
							value: entries[index][1]
						});
					}
				}
			}

			return items;
		},
		getDefaultFilterRuleModel() {
			return {
				//op: 'eq',
				op: 'gt',
				field: null,
				data: null,
				caseSensitiveAndAccents: false
			};
		},
		toggleTodaySelector() {
			this.todaySelector == 'TODAY' ? (this.todaySelector = 'NOW') : (this.todaySelector = 'TODAY');
		},
		todaySelectorTooltip() {
			return this.todaySelector == 'TODAY' ? this.$t('puidatatables.todaySelector.today') : this.$t('puidatatables.todaySelector.now');
		},
		onTodayOperatorValue2KeyDown(e) {
			// prevent -, +, e
			if (['-', '+', 'e'].includes(e.key)) {
				e.preventDefault();
			}
		}
	}
};
</script>

<style lang="postcss">
.RulesFormFilterRule {
	height: auto;
	& .v-input__control {
		min-height: 30px !important;
	}
	& .v-input__slot {
		min-height: 30px !important;
		height: 30px !important;

		& label {
			position: relative !important;
		}
	}
	& .v-text-field__details {
		display: none;
	}
	& .v-text-field.v-text-field--solo .v-input__control {
		min-height: 32px !important;
	}

	& input::placeholder {
		color: black !important;
		opacity: 1;
	}

	.inputFilter,
	.inputFilterText {
		& .v-input__slot {
			height: 39px !important;

			& .v-text-field__slot {
				top: -2px;
			}
		}
	}
	.narrow-icon .v-input__icon {
		width: 4px;
		min-width: 4px;
	}
	.operator {
		min-width: 102px;
	}
}
</style>
