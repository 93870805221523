<template>
	<v-container class="puiFilterGroup-custom pa-0 mx-1">
		<rules-form-filter-group
			v-if="columns.length > 0"
			ref="group0"
			:columnsProp="columns"
			:filterColumnValues="filterColumnValues"
			:filterGroupProp="filterModel"
			:disabled="disabled"
			:showSubgroupBtn="showSubgroupBtn"
			isRootGroup
			@change="onFilterGroupChanges"
			:maxFilters="maxFilters"
		></rules-form-filter-group>
		<!-- saving mode-->
		<v-card-actions v-if="savingMode" class="pa-1 pb-0" :ref="'btnBar'">
			<!-- <v-btn text @click="cancelSave()" class="elevation-0">{{ $t('form.cancel') }}</v-btn>
							<v-spacer></v-spacer> -->
			<v-btn :color="primaryColor" @click="checkFilterNameBeforeSave()" class="elevation-0">{{ $t('form.save') }}</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
//import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import RulesFormFilterGroup from '../rules_form_filter/RulesFormFilterGroup.vue';

export default {
	name: 'RulesFormFilter',
	//mixins: [PuiFormMethodsMixin],
	components: {
		RulesFormFilterGroup
	},
	data() {
		return {
			filterModel: null,
			modelName: 'pmfiltercondition',
			modelDependencyName: 'pmfiltercondition',
			//model: { filter: 'test' },
			savingMode: false,
			primaryColor: this.$store.state.global.primaryColor
			//showSubgroupBtn: true
		};
	},
	props: {
		filterProp: {
			//type: Object,
			required: true
		},
		columns: {
			type: Array,
			required: true
		},
		filterColumnValues: {
			type: Object,
			required: false
		},
		disabled: {
			type: Boolean,
			required: true
		},
		maxFilters: {
			type: Number,
			required: true
		},
		showSubgroupBtn: {
			type: Boolean,
			default: true
		}
		/* modelData: {
			type: Object,
			required: true,
			default() {
				return { message: 'hello' };
			}
		} */
	},
	watch: {
		filterProp() {
			this.filterModel = this.filterProp;
		},
		filterModel: {
			deep: true,
			handler(val, prev) {
				console.info('filterModel watcher', prev, val);
				this.$emit('change', this.filterModel);
				if (val && prev) {
					console.info('watcher emit updateKibana', prev, val);
					this.$emit('mustUpdateKibana', true);
				}
			}
		}
	},
	created() {
		this.filterModel = this.filterProp;
	},
	methods: {
		retrieveGroupsData() {
			// validation - al menos una regla por filtro

			let validFilter = true;
			let almenosUnaRegla = false;
			const validate = (filterGroupModel) => {
				if (filterGroupModel) {
					for (const index in filterGroupModel.rules) {
						almenosUnaRegla = true;
						let rule = filterGroupModel.rules[index];
						if (!rule.field || !rule.op || (!rule.data && rule.op !== 'nn' && rule.op !== 'nu')) {
							this.$puiNotify.warning(this.$t('puidatatables.not-valid-rule-error'));
							validFilter = false;
							return;
						}
						if (typeof rule.data !== 'object' && (rule.op === 'in' || rule.op === 'ni')) {
							rule.data = rule.data.split(',');
						}
					}
					for (const index in filterGroupModel.groups) {
						validate(filterGroupModel.groups[index]);
					}
				}
			};
			this.filterModel && validate(this.filterModel);
			//console.log('retrieveGroupsData', this.filterModel);
			if (almenosUnaRegla === false) {
				this.$puiNotify.warning(this.$t('puidatatables.no-rules-error'));
				return null;
			} else if (validFilter === true) {
				return this.filterModel;
			}

			return null;
		},
		onFilterGroupChanges() {
			this.filterModel = this.$refs.group0.filterGroupModel;
			//console.log('onFilterGroupChanges: filterModel', this.filterModel, this.$parent);
		}
		/* checkFilterNameBeforeSave() {
			this.method = 'create';
			this.model = this.modelData;
			console.debug('debug session & functionalities',  this.$store.getters.getSession,  this.$store.getters.getSession.functionalities);
			if (this.model.pmorganizationid && this.model.filter && this.model.model) {
				this.save(true);
				return true;
			}
			return false;
		} */
	}
};
</script>
