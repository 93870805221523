<template>
	<v-stepper v-model="currentStep" flat>
		<v-stepper-header>
			<v-stepper-step class="pointer" :complete="currentStep > 1" step="1" @click="currentStep = 1">
				{{ $t('telegram.setup.step1.name') }}
			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step class="pointer" :complete="currentStep > 2" step="2" @click="currentStep = 2">
				{{ $t('telegram.setup.step2.name') }}
			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step class="pointer" :complete="currentStep > 3" step="3" @click="currentStep = 3">
				{{ $t('telegram.setup.step2.name') }}
			</v-stepper-step>
		</v-stepper-header>

		<v-stepper-items>
			<v-stepper-content class="pa-2" step="1">
				<v-card class="d-flex flex-column justify-start" color="transparent" flat height="350px">
					<v-row no-gutters>
						<v-col>
							<v-card-title class="fs-xl">{{ $t('telegram.setup.step1.title') }}</v-card-title>
							<v-card-text>
								<ul class="fs-regular">
									<li class="py-2">
										{{ $t('telegram.setup.step1.list.item1')
										}}<span class="fw-bold">{{ $t('telegram.setup.step1.list.item1bold') }}</span
										>.
									</li>
									<li class="py-2">{{ $t('telegram.setup.step1.list.item2') }}</li>
									<li class="py-2">{{ $t('telegram.setup.step1.list.item3') }}</li>
									<li class="py-2">
										{{ $t('telegram.setup.step1.list.item4part1') }}
										<a class="fw-500 text-decoration-underline" @click="currentStep++">
											{{ $t('telegram.setup.step1.list.item4link') }}
										</a>
										{{ $t('telegram.setup.step1.list.item4part2') }}
									</li>
								</ul>
							</v-card-text>
						</v-col>
						<v-col class="d-flex align-center justify-center">
							<v-img
								src="../../assets/telegram/new_group.png"
								alt="New group"
								contain
								:max-height="$vuetify.breakpoint.md ? '200px' : '300px'"
							></v-img>
						</v-col>
					</v-row>
				</v-card>
				<div class="pa-1 d-flex justify-end">
					<v-btn color="primary ml-2" @click="currentStep++"> {{ $t('wizard.next') }} </v-btn>
				</div>
			</v-stepper-content>

			<v-stepper-content class="pa-2" step="2">
				<v-card class="d-flex flex-column justify-start" color="transparent" flat height="350px">
					<v-row no-gutters>
						<v-col>
							<v-card-title class="fs-xl">{{ $t('telegram.setup.step2.title') }}</v-card-title>
							<v-card-text>
								<ul class="fs-regular">
									<li class="py-2">
										{{ $t('telegram.setup.step2.list.item1')
										}}<span class="fw-bold">{{ $t('telegram.setup.step2.list.item1bold') }}</span
										>.
									</li>
									<li class="py-2">{{ $t('telegram.setup.step2.list.item2') }}</li>
									<li class="py-2">{{ $t('telegram.setup.step2.list.item3') }}</li>
									<li class="py-2">
										{{ $t('telegram.setup.step2.list.item4part1')
										}}<span class="fw-bold">{{ $t('telegram.setup.step2.list.item4bold1') }}</span
										>{{ $t('telegram.setup.step2.list.item4part2')
										}}<span class="fw-bold">{{ $t('telegram.setup.step2.list.item4bold2') }}</span
										>{{ $t('telegram.setup.step2.list.item4part3') }}
									</li>
								</ul>
							</v-card-text>
						</v-col>
						<v-col cols="6" md="8" class="d-flex align-center justify-center">
							<v-img
								src="../../assets/telegram/bot_info.png"
								alt="Add Bot"
								contain
								max-width="250"
								:height="$vuetify.breakpoint.md ? '250px' : '300px'"
							></v-img>
							<v-img
								src="../../assets/telegram/manage_bot.png"
								alt="Bot Permissions"
								contain
								max-width="250"
								:height="$vuetify.breakpoint.md ? '250px' : '300px'"
							></v-img>
						</v-col>
					</v-row>
				</v-card>

				<div class="pa-1 d-flex justify-space-between">
					<v-btn class="" @click="currentStep--"> {{ $t('wizard.previous') }} </v-btn>
					<v-btn color="primary ml-2" @click="currentStep++"> {{ $t('wizard.next') }} </v-btn>
				</div>
			</v-stepper-content>

			<v-stepper-content class="pa-2" step="3">
				<v-card class="d-flex flex-column justify-start" color="transparent" flat height="350px">
					<v-row no-gutters>
						<v-col>
							<v-card-title class="fs-xl">{{ $t('telegram.setup.step3.title') }}</v-card-title>
							<v-card-text>
								<ul class="fs-regular">
									<li class="py-2">
										{{ $t('telegram.setup.step3.list.item1part1')
										}}<span class="fw-bold">{{ $t('telegram.setup.step3.list.item1bold') }}</span
										>{{ $t('telegram.setup.step3.list.item1part2') }}
									</li>
									<li class="py-2">{{ $t('telegram.setup.step3.list.item2') }}</li>
									<li class="py-2">
										{{ $t('telegram.setup.step3.list.item3part1')
										}}<span class="fw-bold">{{ $t('telegram.setup.step3.list.item3bold') }}</span
										>{{ $t('telegram.setup.step3.list.item3part2') }} <span class="fw-bold">{{ $t('pmrules.channelid') }}</span
										>.
									</li>
									<li class="py-2">{{ $t('telegram.setup.step3.list.item4') }}</li>
								</ul>
							</v-card-text>
						</v-col>
						<v-col class="d-flex align-center justify-center" :class="$vuetify.breakpoint.md ? 'flex-column' : 'flex-auto'">
							<v-img
								contain
								src="../../assets/telegram/get_ids_bot.png"
								alt="GetIDs Bot"
								:class="$vuetify.breakpoint.md ? 'pa-0' : 'pr-1'"
								max-width="250"
							/>
							<v-img
								contain
								src="../../assets/telegram/chat_id.png"
								alt="Chat ID message"
								:class="$vuetify.breakpoint.md ? 'pa-0' : 'pl-1'"
								max-width="250"
							/>
						</v-col>
					</v-row>
				</v-card>
				<div class="pa-1 d-flex justify-start">
					<v-btn @click="currentStep--"> {{ $t('wizard.previous') }} </v-btn>
				</div>
			</v-stepper-content>
		</v-stepper-items>
	</v-stepper>
</template>

<script>
export default {
	data() {
		return {
			currentStep: 1
		};
	}
};
</script>

<style></style>
